import { IEventRegistrationTypeDescriptorDto } from '.';
import { eProfileType } from '..';
import { eMemberRole } from '../enums';
import { IMemberRegistrationDescriptorDto } from './IMemberRegistrationDescriptorDto';

export interface IMemberEventDescriptorDto {
    registrationIds?: string[];
    registrations: IMemberRegistrationDescriptorDto[];
    registeredInEvent: boolean;
    allowMultipleRegistrations: boolean;
    availableRegistrationTypes?: IEventRegistrationTypeDescriptorDto[];
    memberId: string;
    firstName?: string;
    lastName?: string;
    memberRole: eMemberRole;
    birthDate: string;
    memberIdent: number;
    profileId: string;
    profileType: eProfileType;
    email?: string;
    institutionId: string;
    institutionProfileId: string;
    profileToken: string;
    institutionProfileIdent: number;
    enrollmentId: string;
    collectDemographics: boolean;
}
