import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ManagerClubActions } from 'app/core/containers/admin/manager/enrollments/club/store';
import {
    getClubSearchSelectedCountyAreaId,
    getManagerActiveClubs,
    getManagerClub,
    getManagerClubs,
    getManagerClubsByProgramYear,
    getManagerClubsNotInProgramYear,
    ManagerFeatureStore
} from 'app/core/containers/admin/manager/manager-feature.reducer';
import {
    IClubCharterUpdateDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IUnitCreateDto,
    IUnitDto,
    IUnitProgramYearUpdateDto,
    IUnitUpdateDto,
    IVolunteerACLDto
} from 'app/core/models';
import { downloadFile } from 'app/shared/utils';
import { distinctUntilChanged, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ManagerClubsService {

    public clubSearchSelectedCountyAreaId$: Observable<string>;

    public get clubSearchSelectedCountyAreaId(): string {
        let clubSearchSelectedCountyAreaId: string;
        this.clubSearchSelectedCountyAreaId$.pipe(take(1)).subscribe(id => clubSearchSelectedCountyAreaId = id);
        return clubSearchSelectedCountyAreaId;
    }

    constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject) {
        this.clubSearchSelectedCountyAreaId$ = this.store.pipe(select(getClubSearchSelectedCountyAreaId), distinctUntilChanged());
    }

    public setClubSearchCountyAreaId(countyAreaId: string) {
        this.store.dispatch(ManagerClubActions.SetClubSearchCountyAreaIdAction({ countyAreaId }));
    }

    public getClubsByProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }): Observable<IUnitDto[]> {
        // Optional parameter can be undefined, messes with store so we'll set it to a string
        params.countyAreaId = params.countyAreaId == null ? 'undefined' : params.countyAreaId;
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerClubsByProgramYear(params)),
            tap(clubs => {
                if (clubs === undefined) {
                    this.store.dispatch(ManagerClubActions.ManagerLoadUnitsByProgramYearAction(params));
                }
            }),
            filter(clubs => clubs != null)
        );
    }

    public getClubsByProgramYearEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }): Observable<IUnitDto[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${programYearId}?countyAreaId=${countyAreaId}`) as Observable<IUnitDto[]>;
    }

    public getClubsNotInProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }) {
        // Optional parameter can be undefined, messes with store so we'll set it to a string
        params.countyAreaId = params.countyAreaId == null ? 'undefined' : params.countyAreaId;
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerClubsNotInProgramYear(params)),
            tap(clubs => {
                if (clubs === undefined) {
                    this.store.dispatch(ManagerClubActions.ManagerLoadUnitsNotInProgramYearAction(params));
                }
            }),
            filter(clubs => clubs != null)
        );
    }

    public getClubsNotInProgramYearEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { programYearId: string, countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/~${programYearId}?countyAreaId=${countyAreaId}`) as Observable<IUnitDto[]>;
    }

    public getClubs(params: IInstitutionManagerProgramParams): Observable<IUnitDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerClubs(params)),
            tap(clubs => {
                if (clubs === undefined) {
                    this.store.dispatch(ManagerClubActions.ManagerLoadUnitsAction(params));
                }
            }),
            filter(clubs => clubs != null)
        );
    }

    public getClubsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units?pagesize=0&pageindex=0`) as Observable<IUnitDto[]>;
    }

    public getClub(params: IInstitutionManagerProgramParams & { unitId: string }): Observable<IUnitDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerClub(params)),
            tap(unit => {
                if (unit === undefined) {
                    this.store.dispatch(ManagerClubActions.ManagerLoadUnitAction(params));
                }
            }),
            filter(unit => unit != null)
        );
    }

    public loadClubEffect({
        institutionId,
        managerId,
        programId,
        unitId
    }: IInstitutionManagerProgramParams & { unitId: string }): Observable<IUnitDto> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}`) as Observable<IUnitDto>;
    }

    public createClub(params: IInstitutionManagerProgramParams & { programYearId: string, newClub: IUnitCreateDto }): Observable<IUnitDto> {
        this.store.dispatch(ManagerClubActions.ManagerCreateUnitAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerCreateUnitSuccessAction, ManagerClubActions.ManagerCreateUnitErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerCreateUnitSuccessAction.type) {
                    return action.unit;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createClubEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        newClub
    }: IInstitutionManagerProgramParams & { programYearId: string, newClub: IUnitCreateDto }): Observable<IUnitDto> {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${programYearId}`, newClub,
                { observe: 'response' })
            .pipe(
                switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
            ) as Observable<IUnitDto>;
    }

    public updateUnit(params: IInstitutionManagerProgramParams & { unitId: string, unit: IUnitUpdateDto }): Observable<IUnitUpdateDto> {

        this.store.dispatch(ManagerClubActions.ManagerUpdateUnitAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUpdateUnitSuccessAction, ManagerClubActions.ManagerUpdateUnitErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUpdateUnitSuccessAction.type) {
                    return action.unit;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateUnitEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        unit
    }: IInstitutionManagerProgramParams & { unitId: string, unit: IUnitUpdateDto }): Observable<IUnitUpdateDto> {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}`, unit) as Observable<IUnitUpdateDto>;
    }

    // public removeClubFromProgramYear(params: IInstitutionManagerProgramParams & { unitId: string }): Observable<any> {
    //   this.store.dispatch(ManagerClubActions.RemoveClubFromProgramYearAction(params));
    //   return this.dispatcher.pipe(
    //     ofType(ManagerClubActions.ManagerDeleteUnitSuccessAction, ManagerClubActions.ManagerDeleteUnitErrorAction),
    //     take(1),
    //     map(action => {
    //       if (action.type === ManagerClubActions.ManagerDeleteUnitSuccessAction.type) {
    //         return action;
    //       } else {
    //         throw action.error;
    //       }
    //     })
    //   );
    // }

    // public removeClubFromProgramYearEffect({ institutionId, managerId, programId, unitId }: IInstitutionManagerProgramParams & { unitId: string }): Observable<any> {
    //   return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}`) as Observable<any>;
    // }

    public linkProject(params: IInstitutionManagerProgramParams & { unitId: string, projects: Array<string> }) {
        this.store.dispatch(ManagerClubActions.ManagerLinkProjectAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerLinkProjectSuccessAction, ManagerClubActions.ManagerLinkProjectErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerLinkProjectSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );

    }

    public linkProjectEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        projects
    }: IInstitutionManagerProgramParams & { unitId: string, projects: Array<string> }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/projects`, projects) as Observable<string[]>;
    }

    public unlinkProject(params: IInstitutionManagerProgramParams & { unitId: string, projectId: string }) {
        this.store.dispatch(ManagerClubActions.ManagerUnlinkProjectAction(params));
    }

    public unlinkProjectEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        projectId
    }: IInstitutionManagerProgramParams & { unitId: string, projectId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/projects/${projectId}`);
    }

    public getMultipleClubsEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        countyAreaIds
    }: IInstitutionManagerProgramParams & { programYearId: string, countyAreaIds: string[] }) {
        if (countyAreaIds.length < 1) {
            return of(null);
        }
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${programYearId}`, countyAreaIds) as Observable<IUnitDto[]>;
    }

    public updateClubChartering(params: IInstitutionManagerProgramParams & { programYearId: string, unitId: string, club: IClubCharterUpdateDto }) {
        this.store.dispatch(ManagerClubActions.ManagerUpdateClubCharteringAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUpdateClubCharteringSuccessAction, ManagerClubActions.ManagerUpdateClubCharteringErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUpdateClubCharteringSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateClubCharteringEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId,
        club
    }: IInstitutionManagerProgramParams & { programYearId: string, unitId: string, club: IClubCharterUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/program-years/${programYearId}/chartering`, club);
    }

    public updateClubCharterDocument(params: IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, file: any }) {
        this.store.dispatch(ManagerClubActions.ManagerUpdateClubCharteringDocumentAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUpdateClubCharteringDocumentSuccessAction, ManagerClubActions.ManagerUpdateClubCharteringDocumentErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUpdateClubCharteringDocumentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateClubCharterDocumentEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId,
        fileControlId,
        file
    }: IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, file: any }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/program-years/${programYearId}/chartering-file/${fileControlId}`, file);
    }

    public deleteClubCharterDocument(params: IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, }) {
        this.store.dispatch(ManagerClubActions.ManagerDeleteClubCharteringDocumentAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerDeleteClubCharteringDocumentSuccessAction, ManagerClubActions.ManagerDeleteClubCharteringDocumentErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerDeleteClubCharteringDocumentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteClubCharterDocumentEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId,
        fileControlId
    }: IInstitutionManagerProgramParams & { programYearId: string, fileControlId: string, unitId: string, }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/program-years/${programYearId}/chartering-file/${fileControlId}`);
    }

    public getClubCharteringFile(params: IInstitutionManagerProgramParams & { unitId: string, programYearId: string, fileId: string }) {
        this.store.dispatch(ManagerClubActions.ManagerGetClubCharteringFileAction(params));
    }

    public getClubCharteringFileEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId,
        fileId
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string, fileId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/clubs/${unitId}/program-years/${programYearId}/chartering-file/${fileId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/octet-stream'
            }), responseType: 'blob'
        });
    }

    public getActiveClubs(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IUnitDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }

        return this.store.pipe(
            select(getManagerActiveClubs(params)),
            tap(clubs => {
                if (clubs === undefined) {
                    this.store.dispatch(ManagerClubActions.ManagerGetActiveUnitsAction(params));
                }
            }),
            filter(clubs => clubs != null)
        );
    }

    public getActiveClubsEffect({
        institutionId,
        managerId,
        programId,
        programYearId
    }: IInstitutionManagerProgramParams & { programYearId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${programYearId}?pagesize=0&pageindex=0`) as Observable<IUnitDto[]>;
    }

    public updateEnrollmentRestrictions(params: IInstitutionManagerProgramParams & { unitId: string, programYearId: string, update: IUnitProgramYearUpdateDto }) {
        this.store.dispatch(ManagerClubActions.ManagerUpdateEnrollmentRestrictionsAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUpdateEnrollmentRestrictionsSuccessAction, ManagerClubActions.ManagerUpdateEnrollmentRestrictionsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUpdateEnrollmentRestrictionsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEnrollmentRestrictionsEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId,
        update
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string, update: IUnitProgramYearUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/program-years/${programYearId}`, update);
    }

    public updateClubLeaderPermissions(params: IInstitutionManagerProgramParams & { unitLeaderId: string, permissions: IVolunteerACLDto }) {
        this.store.dispatch(ManagerClubActions.ManagerUpdateUnitLeaderPermissionsAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUpdateUnitLeaderPermissionsSuccessAction, ManagerClubActions.ManagerUpdateUnitLeaderPermissionsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUpdateUnitLeaderPermissionsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateClubLeaderPermissionsEffect({
        institutionId,
        managerId,
        programId,
        unitLeaderId,
        permissions
    }: IInstitutionManagerProgramParams & { unitLeaderId: string, permissions: IVolunteerACLDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/unit-leaders/${unitLeaderId}/permissions`, permissions);
    }

    public downloadUnitsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        programYearId
    }: IInstitutionManagerProgramHierarchyParams & { programYearId: string }) {
        return downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/unit-list/${hierarchyNodeId}?programYearId=${programYearId}`);
    }

    // TODO Remove dtos
    // public UpdateUnitCharacteristics(params: IInstitutionManagerProgramParams & { unitId: string, characteristicsUpdate: IUnitUpdateCharacteristicsDto }) {

    // }
    // public UpdateUnitCharacteristicsEffect({ institutionId, managerId, programId, unitId, characteristicsUpdate }: IInstitutionManagerProgramParams & { unitId: string, characteristicsUpdate: IUnitUpdateCharacteristicsDto }) {
    //   return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/characteristics`, characteristicsUpdate);
    // }

    // public UpdateUnitContactAddress(params: IInstitutionManagerProgramParams & { unitId: string, characteristicsUpdate: IUnitUpdateContactAndAddressDto }) {

    // }
    // public UpdateUnitContactAddressEffect({ institutionId, managerId, programId, unitId, contractAddressUpdate }: IInstitutionManagerProgramParams & { unitId: string, contractAddressUpdate: IUnitUpdateContactAndAddressDto }) {
    //   return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/contact-address`, contractAddressUpdate);
    // }

    public downloadRosterEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/unit-roster/${unitId}?programYearId=${programYearId}`);
    }

    public downloadConsentsEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/unit-consents-pdf/${unitId}?programYearId=${programYearId}`);
    }

    public downloadHealthEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/unit-health-form-pdf/${unitId}?programYearId=${programYearId}`);
    }

    public linkUnitToProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string, unitIds: string[] }) {
        this.store.dispatch(ManagerClubActions.ManagerLinkUnitToProgramYearAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerLinkUnitToProgramYearSuccessAction, ManagerClubActions.ManagerLinkUnitToProgramYearErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerLinkUnitToProgramYearSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public linkUnitToProgramYearEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        unitIds
    }: IInstitutionManagerProgramParams & { programYearId: string, unitIds: string[] }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/program-years/${programYearId}`, unitIds);
    }

    public unLinkUnitToProgramYear(params: IInstitutionManagerProgramParams & { unitId: string, programYearId: string }) {
        this.store.dispatch(ManagerClubActions.ManagerUnLinkUnitToProgramYearAction(params));

        return this.dispatcher.pipe(
            ofType(ManagerClubActions.ManagerUnLinkUnitToProgramYearSuccessAction, ManagerClubActions.ManagerUnLinkUnitToProgramYearErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerClubActions.ManagerUnLinkUnitToProgramYearSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public unLinkUnitToProgramYearEffect({
        institutionId,
        managerId,
        programId,
        unitId,
        programYearId
    }: IInstitutionManagerProgramParams & { unitId: string, programYearId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/units/${unitId}/program-years/${programYearId}`);
    }

}
