import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AutoUnsubscribe,
  eEventRegistrationTypeYouthRestrictionType,
  eHealthFormType,
  eRegistrationRoleType,
  eSchoolGradeType,
  IAnimalTypeDto,
} from 'app/core/models';
import { CamelToTitleCasePipe } from 'app/core/pipes';
import {
  CommonToastrService,
  EventsManagerService,
  ProgramSettingsService,
  ProgramYearService,
  RouterService,
} from 'app/core/services';
import { ManagerAnimalsService } from 'app/core/services/manager/animals/manager-animals.service';
import { retainOrder } from 'app/shared/utils';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';

@Component({
  selector: 'ng4h-modal-add-registration-type',
  templateUrl: './modal-add-registration-type.component.html',
  styleUrls: ['./modal-add-registration-type.component.scss']
})
export class ModalAddRegistrationTypeComponent extends AutoUnsubscribe implements OnInit {

  public schoolGradeOptions: { key: string, display: string }[];
  public ageOptions: { key: string, display: string }[];
  public eSchoolGradeType = eSchoolGradeType;
  public eEventRegistrationTypeYouthRestrictionType = eEventRegistrationTypeYouthRestrictionType;
  public form: FormGroup;
  public eHealthFormType = eHealthFormType;
  public retainOrder = retainOrder;

  public youthDivisionOptions$: Observable<{ key: string, display: string }[]>;
  public animalTypes$: Observable<IAnimalTypeDto[]>;

  public eRegistrationRoleType = eRegistrationRoleType;

  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private eventsService: EventsManagerService,
    private routerService: RouterService,
    private programYearService: ProgramYearService,
    private toastrService: CommonToastrService,
    private programSettingsService: ProgramSettingsService,
    private mangerAnimalsService: ManagerAnimalsService,
  ) {
    super();

    this.animalTypes$ = this.mangerAnimalsService.getAnimalTypes({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    });


    combineLatest([
      this.eventsService.getEvent({
        institutionId: this.routerService.institutionId,
        managerId: this.routerService.managerId,
        programId: this.routerService.programId,
        eventId: this.routerService.eventId
      }),
      this.programYearService.getCurrentProgramYear({
        institutionId: this.routerService.institutionId,
        programId: this.routerService.programId
      })]).pipe(
        filter(combined => combined.every(c => c != null)),
        take(1)
      ).subscribe(([event, programYear]) => {

        this.form = this.fb.group({
          registrationTypeName: [null, [Validators.required]],
          registrationRole: [eRegistrationRoleType.ClubMember],
          healthFormType: [eHealthFormType.Youth],
          // enrollmentRoleActiveProgramYear: [programYear.programYearId, [Validators.required]],
          maximumRegistrations: [0, [Validators.required, Validators.min(0)]],
          allowActiveInPreviousProgramYear: [false],
          initiateRegistrationByManagerOnly: [false],
          unrestrictedParticipant: [false],
          depositRequired: [false],
          depositAmount: [{ value: null, disabled: true }],
          depositFullPaymentDueDate: [{ value: null, disabled: true }],
          registrationFee: [0, [Validators.required, Validators.min(0)]],
          registrationStartDate: [event.startDate, [Validators.required]],
          registrationEndDate: [event.endDate, [Validators.required]],
          allowEarlyRegistration: [false],
          earlyRegistrationFee: [{ value: null, disabled: true }, [Validators.min(0), Validators.required]],
          earlyRegistrationStartDate: [{ value: null, disabled: true }, [Validators.required]],
          earlyRegistrationEndDate: [{ value: null, disabled: true }, [Validators.required]],
          allowLateRegistration: [false],
          lateRegistrationFee: [{ value: null, disabled: true }, [Validators.min(0), Validators.required]],
          lateRegistrationStartDate: [{ value: null, disabled: true }, [Validators.required]],
          lateRegistrationEndDate: [{ value: null, disabled: true }, [Validators.required]],
          restrictionType: [],
          programAges: [],
          ages: [],
          schoolGrades: [],
          programYouthDivisions: [],
          animalTypes: []
        });

        this.form.controls.allowEarlyRegistration.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(allowEarlyRegistration => {
          if (allowEarlyRegistration) {
            this.form.controls.earlyRegistrationFee.enable();
            this.form.controls.earlyRegistrationStartDate.enable();
            this.form.controls.earlyRegistrationEndDate.enable();
          } else {
            this.form.controls.earlyRegistrationFee.disable();
            this.form.controls.earlyRegistrationStartDate.disable();
            this.form.controls.earlyRegistrationEndDate.disable();
          }
          this.form.updateValueAndValidity();
        });

        this.form.controls.allowLateRegistration.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(allowLateRegistration => {
          if (allowLateRegistration) {
            this.form.controls.lateRegistrationFee.enable();
            this.form.controls.lateRegistrationStartDate.enable();
            this.form.controls.lateRegistrationEndDate.enable();
          } else {
            this.form.controls.lateRegistrationFee.disable();
            this.form.controls.lateRegistrationStartDate.disable();
            this.form.controls.lateRegistrationEndDate.disable();
          }
          this.form.updateValueAndValidity();
        });

        this.form.controls.depositRequired.valueChanges.subscribe(val => {
          if (val === true) {
            this.form.controls.depositAmount.setValidators(Validators.required);
            this.form.controls.depositAmount.enable();
            this.form.controls.depositAmount.updateValueAndValidity();

            this.form.controls.depositFullPaymentDueDate.setValidators(Validators.required);
            this.form.controls.depositFullPaymentDueDate.enable();
            this.form.controls.depositFullPaymentDueDate.updateValueAndValidity();
          } else {
            this.form.controls.depositAmount.disable();
            this.form.controls.depositAmount.setValue(null);
            this.form.controls.depositAmount.clearValidators();
            this.form.controls.depositAmount.updateValueAndValidity();

            this.form.controls.depositFullPaymentDueDate.disable();
            this.form.controls.depositFullPaymentDueDate.setValue(null);
            this.form.controls.depositFullPaymentDueDate.clearValidators();
            this.form.controls.depositFullPaymentDueDate.updateValueAndValidity();
          }
        });

        // this.form.controls.depositAmount.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(val => {
        //   if (val != null && val !== 0) {
        //     this.form.controls.depositFullPaymentDueDate.setValidators(Validators.required);
        //     this.form.controls.depositFullPaymentDueDate.enable();
        //     this.form.controls.depositFullPaymentDueDate.updateValueAndValidity();

        //   } else {
        //     this.form.controls.depositFullPaymentDueDate.disable();
        //     this.form.controls.depositFullPaymentDueDate.setValue(null);
        //     this.form.controls.depositFullPaymentDueDate.clearValidators();
        //     this.form.controls.depositFullPaymentDueDate.updateValueAndValidity();
        //   }
        // });

        this.form.controls.restrictionType.valueChanges.pipe(takeUntil(this.autoUnsubscribe)).subscribe(val => {
          this.form.controls.programAges.setValue(null);
          this.form.controls.schoolGrades.setValue(null);
          this.form.controls.programYouthDivisions.setValue(null);

          this.form.controls.programAges.updateValueAndValidity();
          this.form.controls.schoolGrades.updateValueAndValidity();
          this.form.controls.programYouthDivisions.updateValueAndValidity();
        });


      });

    this.youthDivisionOptions$ = this.programSettingsService.getProgramSettings({
      institutionId: this.routerService.institutionId,
      programId: this.routerService.programId
    }).pipe(

      map(programSettings => {
        return programSettings.youthDivisions?.map(youthDivision => {
          return { key: youthDivision.youthDivisionId, display: youthDivision.youthDivisionLabel };
        });
      })
    );
  }

  ngOnInit() {
    const transform = new CamelToTitleCasePipe().transform;

    this.schoolGradeOptions = Object.keys(eSchoolGradeType).map(key => {
      return { key: key, display: transform(eSchoolGradeType[key]) };
    });

    this.ageOptions = [];
    for (let i = 1; i < 99; i++) {
      this.ageOptions.push({ key: i.toString(), display: i.toString() });
    }
  }

  public schoolGradeChanged(grades: any) {
    this.form.controls.schoolGrades.setValue(grades.map(g => g.key));
  }
  public programAgeChanged(ages: any) {
    this.form.controls.programAges.setValue(ages.map(g => g.key));
  }
  public ageChanged(ages: any) {
    this.form.controls.ages.setValue(ages.map(g => g.key));
  }
  public youthDivisionChanged(youthDivisions: any) {
    this.form.controls.programYouthDivisions.setValue(youthDivisions.map(g => g.key));
  }
  public add() {
    this.eventsService.createRegistrationType({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      eventRegistrationTypeCreateDto: this.form.value
    })
      .subscribe({
        next: registrationType => {
          this.toastrService.success('update registration type success');
          this.close();
        }, error: error => {
          this.toastrService.error('registration type update failed');
        }
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public animalTypesChanged(animalTypes: IAnimalTypeDto[]) {
    this.form.controls.animalTypes.setValue(animalTypes.map(animalType => animalType.animalTypeId));
  }


}
