import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
    AppState,
    getManagerCountyOutstandingCredits,
    getManagerCrossEventRegistrations,
    getManagerEvent,
    getManagerEventConsents,
    getManagerEventCoupon,
    getManagerEventCoupons,
    getManagerEventOutstandingCredits,
    getManagerEventRegistrations,
    getManagerEvents,
    getManagerGroupEnrollments,
    getRegistrationSearchSearching
} from 'app/app.reducers';
import {
    IBulkDownloadCreateDto,
    IConsentTypeDto,
    ICreditDto,
    ICreditsCollectionDto,
    IEventACLDto,
    IEventCouponDto,
    IEventCreateDto,
    IEventDescriptorDto,
    IEventDiscountCreateDto,
    IEventDiscountUpdateDto,
    IEventDto,
    IEventEmailConfigurationDto,
    IEventGroupEnrollmentCreateDto,
    IEventHierarchyRestrictionUpdateDto, IEventInstructionsUpdateDto,
    IEventRegistrationManagerReviewDto,
    IEventRegistrationSearchParametersDto,
    IEventRegistrationSearchResultsDto,
    IEventRegistrationTypeCreateDto,
    IEventRegistrationTypeUpdateDto,
    IEventSessionCreateDto,
    IEventSessionUpdateDto, IEventStatusUpdateDto,
    IEventUpdateDto,
    IEventVanityDomainCreateDto,
    IEventVanityDomainUpdateDto,
    IInstitutionManagerProgramEventParams,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionMemberProgramParams
} from 'app/core/models';
import { EventsManagerActions } from 'app/shared/events-manager';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { CommonToastrService } from '.';
import {
    IInstitutionMemberProgramEventRegistrationParams
} from '../models/function-parameters/institution-member-program-event-registration';
import { IEventRegistrationTypeDto } from '../models/serverDTOs/IEventRegistrationTypeDto';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventsManagerService {

    public registrationSearchSearching$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private httpClient: HttpClient,
        private dispatcher: ActionsSubject,
        private toastrService: CommonToastrService
    ) {
        this.registrationSearchSearching$ = this.store.pipe(select(getRegistrationSearchSearching));
    }

    public getRegistrationType({
        institutionId,
        managerId,
        programId,
        eventId,
        registrationTypeId
    }: IInstitutionManagerProgramEventParams & {
        registrationTypeId: string
    }): Observable<IEventRegistrationTypeDto> {
        return this.getEvent({ institutionId, managerId, programId, eventId }).pipe(
            filter(event => event != null),
            map(event => {
                return event.registrationTypes.find(registrationType => registrationType.registrationTypeId === registrationTypeId);
            })
        );
    }

    public getEvent(params: IInstitutionManagerProgramEventParams): Observable<IEventDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEvent(params)),
            tap(event => {
                if (event === undefined) {
                    this.store.dispatch(EventsManagerActions.EventsManagerLoadAction(params));
                }
            }),
            filter(event => event != null)
        );
    }

    public loadEventEffect({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams): Observable<IEventDto> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}`) as Observable<IEventDto>;
    }

    public getEvents(params: IInstitutionManagerProgramHierarchyParams & {
        programYearId: string
    }): Observable<IEventDescriptorDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEvents(params)),
            tap(events => {
                if (events === undefined) {
                    this.store.dispatch(EventsManagerActions.EventsManagerLoadManyAction(params));
                }
            }),
            filter(events => events != null)
        );
    }

    public loadEventsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        programYearId
    }: IInstitutionManagerProgramHierarchyParams & {
        programYearId: string
    }): Observable<IEventDescriptorDto[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/list?programYearId=${programYearId}`) as Observable<IEventDescriptorDto[]>;
    }

    public getEventConsents(params: IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEventConsents(params)),
            tap(eventConsents => {
                if (eventConsents === undefined) {
                    this.store.dispatch(EventsManagerActions.EventsManagerLoadConsentsAction(params));
                }
            }),
            filter(eventConsents => eventConsents != null)
        );
    }

    public loadEventConsentsEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeId
    }: IInstitutionManagerProgramEventParams & { eventRegistrationTypeId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types/${eventRegistrationTypeId}/consents`) as Observable<IConsentTypeDto[]>;
    }

    public addEventConsent(params: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        consentTypeId: string
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerAddConsentAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerAddConsentSuccessAction, EventsManagerActions.EventsManagerAddConsentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerAddConsentSuccessAction.type) {
                    return action.consentTypeId;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public addEventConsentEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeId,
        consentTypeId
    }: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        consentTypeId: string
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types/${eventRegistrationTypeId}/consents?consentTypeId=${consentTypeId}`, consentTypeId);
    }

    public deleteEventConsent(params: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        consentTypeId: string
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerDeleteConsentAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerDeleteConsentSuccessAction, EventsManagerActions.EventsManagerDeleteConsentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerDeleteConsentSuccessAction.type) {
                    return action.consentTypeId;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteEventConsentEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeId,
        consentTypeId
    }: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        consentTypeId: string
    }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types/${eventRegistrationTypeId}/consents?consentTypeId=${consentTypeId}`);
    }

    public createEvent(params: IInstitutionManagerProgramHierarchyParams & { eventCreateDto: IEventCreateDto }) {
        this.store.dispatch(EventsManagerActions.EventsManagerCreateAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerCreateSuccessAction, EventsManagerActions.EventsManagerCreateErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerCreateSuccessAction.type) {
                    return action.event;
                } else {
                    throw action.error;
                }
            })
        );
    }

    // Node id of the active profile
    public createEventEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        eventCreateDto
    }: IInstitutionManagerProgramHierarchyParams & {
        eventCreateDto: IEventCreateDto
    }): Observable<IEventDto> {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}`, eventCreateDto,
            { observe: 'response' })
        .pipe(
            switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
        ) as Observable<IEventDto>;
    }

    public updateEvent(params: IInstitutionManagerProgramEventParams & { eventUpdateDto: IEventUpdateDto }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateSuccessAction, EventsManagerActions.EventsManagerUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateSuccessAction.type) {
                    return action.event;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventUpdateDto
    }: IInstitutionManagerProgramEventParams & { eventUpdateDto: IEventUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}`, eventUpdateDto);
    }

    public updateEventInstructions(params: IInstitutionManagerProgramEventParams & {
        eventInstructionsUpdateDto: IEventInstructionsUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateInstructionsAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateInstructionsSuccessAction, EventsManagerActions.EventsManagerUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateInstructionsSuccessAction.type) {
                    return action.event;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventInstructionsEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventInstructionsUpdateDto
    }: IInstitutionManagerProgramEventParams & {
        eventInstructionsUpdateDto: IEventInstructionsUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/instructions`, eventInstructionsUpdateDto);
    }

    public updateEventStatus(params: IInstitutionManagerProgramEventParams & {
        eventStatusUpdateDto: IEventStatusUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateStatusAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateStatusSuccessAction, EventsManagerActions.EventsManagerUpdateErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateStatusSuccessAction.type) {
                    return action.event;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventStatusEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventStatusUpdateDto
    }: IInstitutionManagerProgramEventParams & {
        eventStatusUpdateDto: IEventStatusUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/status`, eventStatusUpdateDto);
    }

    public createRegistrationType(params: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeCreateDto: IEventRegistrationTypeCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerCreateRegistrationTypeAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerCreateRegistrationTypeSuccessAction, EventsManagerActions.EventsManagerCreateRegistrationTypeErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerCreateRegistrationTypeSuccessAction.type) {
                    return action.eventRegistrationTypeCreateDto;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createRegistrationTypeEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeCreateDto
    }: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeCreateDto: IEventRegistrationTypeCreateDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types`, eventRegistrationTypeCreateDto);
    }

    public updateRegistrationType(params: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        eventRegistrationTypeUpdateDto: IEventRegistrationTypeUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateRegistrationTypeAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateRegistrationTypeSuccessAction, EventsManagerActions.EventsManagerUpdateRegistrationTypeErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateRegistrationTypeSuccessAction.type) {
                    return action.eventRegistrationTypeUpdateDto;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateRegistrationTypeEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeId,
        eventRegistrationTypeUpdateDto
    }: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string,
        eventRegistrationTypeUpdateDto: IEventRegistrationTypeUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types/${eventRegistrationTypeId}`, eventRegistrationTypeUpdateDto);
    }

    // public getSubmittedEventRegistrations(params: IInstitutionManagerProgramHierarchyParams& { eventId: string }): Observable<IEventRegistrationPendingDto[]> {
    //   if (Object.keys(params).find(key => params[key] == null) != null) {
    //     return of(null);
    //   }
    //   return this.store.pipe(
    //     select(getManagerSubmittedEventRegistrations(params)),
    //     tap(submittedEventRegistrations => {
    //       if (submittedEventRegistrations === undefined) {
    //         this.store.dispatch(EventsManagerActions.EventsManagerLoadSubmittedRegistrationsAction(params));
    //       }
    //     }),
    //     filter(submittedEventRegistrations => submittedEventRegistrations != null),
    //   );
    // }

    public loadSubmittedEventRegistrationsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        eventId
    }: IInstitutionManagerProgramHierarchyParams & {
        eventId: string
    }): Observable<any[]> {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/event-registrations/pending-reviews/${hierarchyNodeId}?eventId=${eventId}`) as Observable<any[]>;
    }

    public approve(params: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerApproveAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerApproveSuccessAction, EventsManagerActions.EventsManagerApproveErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerApproveSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public approveEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        managerId,
        review
    }: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/approve?managerId=${managerId}`, review);
    }

    public eventRegistrationSearch(params: IInstitutionManagerProgramEventParams & {
        pagesize: string,
        pageindex: string
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerLoadEventRegistrations(params));
    }

    public getEventRegistrations(params: IInstitutionManagerProgramEventParams): Observable<IEventRegistrationSearchResultsDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEventRegistrations(params)),
            filter(eventRegistrations => eventRegistrations != null)
        );
    }

    public loadEventRegistrationsEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramEventParams & {
        pagesize: string,
        pageindex: string
    }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<IEventRegistrationSearchResultsDto>;
    }

    public downloadRegistrationSearchExcel({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/excel`, {});
    }

    public sendBack(params: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerSendBackAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerSendBackSuccessAction, EventsManagerActions.EventsManagerSendBackErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerSendBackSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public sendBackEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        managerId,
        review
    }: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/send-back?managerId=${managerId}`, review);
    }

    public block(params: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerBlockAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerBlockSuccessAction, EventsManagerActions.EventsManagerBlockErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerBlockSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public blockEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        managerId,
        review
    }: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string,
        review: IEventRegistrationManagerReviewDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/reject?managerId=${managerId}`, review);
    }

    public updateEventPermissions(params: IInstitutionManagerProgramParams & {
        eventId: string,
        targetManagerId: string,
        permissions: IEventACLDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateEventPermissions(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateEventSuccessPermissions, EventsManagerActions.EventsManagerUpdateEventErrorPermissions),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateEventSuccessPermissions.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventPermissionsEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        targetManagerId,
        permissions
    }: IInstitutionManagerProgramEventParams & {
        targetManagerId: string,
        permissions: IEventACLDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${targetManagerId}/programs/${programId}/events/${eventId}/permissions?adminmanagerid=${managerId}`, permissions);
    }

    public removeManagerFromEvent(params: IInstitutionManagerProgramEventParams & { adminManagerId: string }) {
        this.store.dispatch(EventsManagerActions.RemoveManagerFromEventPermissions(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.RemoveManagerFromEventSuccessPermissions, EventsManagerActions.RemoveManagerFromEventErrorPermissions),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.RemoveManagerFromEventSuccessPermissions.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public removeManagerFromEventEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        adminManagerId
    }: IInstitutionManagerProgramEventParams & { adminManagerId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/event-managers?adminManagerId=${adminManagerId}`);
    }

    // Deprecated, event permissions come back on the event or event descriptor
    // public loadManagerPermissionsForEvent(params: IInstitutionManagerProgramEventParams) {
    //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/permissions?adminmanagerid=${managerId}`);
    // }

    public getCrossEventSearchResults(params: IInstitutionManagerProgramHierarchyParams) {
        return this.store.pipe(
            select(getManagerCrossEventRegistrations(params)),
            filter(eventRegistrations => eventRegistrations != null)
        );
    }

    public searchCrossEvents(params: IInstitutionManagerProgramHierarchyParams & {
        pagesize: string,
        pageindex: string
    }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return;
        }
        this.store.dispatch(EventsManagerActions.EventsManagerLoadCrossEventRegistrations(params));
    }

    public crossEventSearchResultsEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramHierarchyParams & {
        pagesize: string,
        pageindex: string
    }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search?pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<IEventRegistrationSearchResultsDto>;
    }

    public crossEventSearch(params: IInstitutionManagerProgramHierarchyParams & {
        searchParams: IEventRegistrationSearchParametersDto
    }) {
        this.store.dispatch(EventsManagerActions.CrossEventRegistrationSearchAction(params));
    }

    public crossEventSearchEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        searchParams
    }: IInstitutionManagerProgramHierarchyParams & {
        searchParams: IEventRegistrationSearchParametersDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search`, searchParams,
            { observe: 'response' }).pipe(
            switchMap((res: HttpResponse<any>) => {
                return this.httpClient.get(res.headers.get('location'));
            })
        ) as Observable<IEventRegistrationSearchResultsDto>;
    }

    public crossEventClearSearch(params: IInstitutionManagerProgramHierarchyParams) {
        this.store.dispatch(EventsManagerActions.CrossEventRegistrationClearSearchAction(params));
    }

    public crossEventClearSearchEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search`);
    }

    public downloadCrossEventSearchExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/excel`);
    }

    public searchEventRegistrations(params: IInstitutionManagerProgramParams & {
        eventId: string,
        searchParams: IEventRegistrationSearchParametersDto
    }) {
        this.store.dispatch(EventsManagerActions.EventRegistrationSearchAction(params));
    }

    public eventRegistrationSearchEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        searchParams
    }: IInstitutionManagerProgramEventParams & {
        searchParams: IEventRegistrationSearchParametersDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search`, searchParams,
            { observe: 'response' }).pipe(
            switchMap((res: HttpResponse<any>) => {
                return this.httpClient.get(res.headers.get('location'));
            })
        ) as Observable<IEventRegistrationSearchResultsDto>;
    }

    // public getEventRegistrationSearchResults() {
    //   return this.dispatcher.pipe(
    //     ofType<EventRegistrationSearchSuccessAction | EventRegistrationSearchErrorAction>(EventsManagerActions.EVENT_REGISTRATION_SEARCH_SUCCESS, EventsManagerActions.EVENT_REGISTRATION_SEARCH_ERROR),
    //     take(1),
    //     map(action => {
    //       if (action.type === EventsManagerActions.EVENT_REGISTRATION_SEARCH_SUCCESS) {
    //         return action;
    //       } else {
    //         throw action.error;
    //       }
    //     })
    //   );
    // }

    public eventRegistrationClearSearch(params: IInstitutionManagerProgramParams & { eventId: string }) {
        this.store.dispatch(EventsManagerActions.EventRegistrationClearSearchAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventRegistrationClearSearchSuccessAction, EventsManagerActions.EventRegistrationClearSearchErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventRegistrationClearSearchSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public eventRegistrationClearSearchEffect({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search`);
    }

    public deleteEvent(params: IInstitutionManagerProgramParams & { eventId: string }) {
        this.store.dispatch(EventsManagerActions.EventsManagerDeleteEventAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerDeleteEventSuccessAction, EventsManagerActions.EventsManagerDeleteEventErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerDeleteEventSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteEventEffect({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}`);
    }

    public deleteEventRegistrationType(params: IInstitutionManagerProgramParams & {
        eventId: string,
        eventRegistrationTypeId: string
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerDeleteRegistrationTypeAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerDeleteRegistrationTypeSuccessAction, EventsManagerActions.EventsManagerDeleteRegistrationTypeErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerDeleteRegistrationTypeSuccessAction.type) {
                    return action;
                } else {

                    throw action.error;
                }
            })
        );
    }

    public deleteEventRegistrationTypeEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventRegistrationTypeId
    }: IInstitutionManagerProgramEventParams & {
        eventRegistrationTypeId: string
    }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/registration-types/${eventRegistrationTypeId}`);
    }

    public uploadEventSplashImageEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        formData
    }: IInstitutionManagerProgramEventParams & { formData: FormData }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/splash`, formData);
    }

    public uploadEventLogoImageEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        formData
    }: IInstitutionManagerProgramEventParams & { formData: FormData }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/logo`, formData);
    }

    public refreshEvent({ institutionId, managerId, programId }: IInstitutionManagerProgramEventParams & {}) {
        this.store.dispatch(EventsManagerActions.EventsManagerInvalidateEventCacheAction());
    }

    public createEventSession(params: IInstitutionManagerProgramEventParams & {
        sessionCreateDto: IEventSessionCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerCreateSessionAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerCreateSessionSuccessAction, EventsManagerActions.EventsManagerCreateSessionErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerCreateSessionSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createEventSessionEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        sessionCreateDto
    }: IInstitutionManagerProgramEventParams & {
        sessionCreateDto: IEventSessionCreateDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/sessions`, sessionCreateDto);
    }

    public updateEventSession(params: IInstitutionManagerProgramEventParams & {
        eventSessionId: string,
        sessionUpdateDto: IEventSessionUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateSessionAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateSessionSuccessAction, EventsManagerActions.EventsManagerUpdateSessionErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateSessionSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventSessionEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventSessionId,
        sessionUpdateDto
    }: IInstitutionManagerProgramEventParams & {
        eventSessionId: string,
        sessionUpdateDto: IEventSessionUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/sessions/${eventSessionId}`, sessionUpdateDto);
    }

    public deleteEventSession(params: IInstitutionManagerProgramEventParams & { eventSessionId: string }) {
        this.store.dispatch(EventsManagerActions.EventsManagerDeleteSessionAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerDeleteSessionSuccessAction, EventsManagerActions.EventsManagerDeleteSessionErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerDeleteSessionSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteEventSessionEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventSessionId
    }: IInstitutionManagerProgramEventParams & { eventSessionId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/sessions/${eventSessionId}`);
    }

    public processPaymentsEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        managerId
    }: IInstitutionMemberProgramParams & {
        eventRegistrationId: string,
        managerId: string
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/process-payments?managerId=${managerId}`, {});
    }

    public getCountyOutstandingCredits(params: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerCountyOutstandingCredits(params)),
            tap(credits => {
                if (credits === undefined) {
                    this.store.dispatch(EventsManagerActions.GetOutstandingCountyCreditsAction(params));
                }
            }),
            filter(credits => credits != null)
        );
    }

    public getCountyOutstandingCreditsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${countyAreaId}/outstanding-credits`) as Observable<ICreditDto[]>;
    }

    public getOutstandingCredits(params: IInstitutionManagerProgramEventParams): Observable<ICreditDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEventOutstandingCredits(params)),
            tap(credits => {
                if (credits === undefined) {
                    this.store.dispatch(EventsManagerActions.GetOutstandingCreditAction(params));
                }
            }),
            filter(credits => credits != null)
        );
    }

    public getOutstandingCreditsEffect({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/outstanding-credits`) as Observable<ICreditDto[]>;
    }

    public confirmCreditCollected(params: IInstitutionManagerProgramEventParams & {
        collectedCredits: ICreditsCollectionDto
    }) {
        this.store.dispatch(EventsManagerActions.ConfirmCreditCollectedAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.ConfirmCreditCollectedSuccessAction, EventsManagerActions.ConfirmCreditCollectedErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.ConfirmCreditCollectedSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public confirmCreditCollectedEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        collectedCredits
    }: IInstitutionManagerProgramEventParams & {
        collectedCredits: ICreditsCollectionDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/credit-collection`, collectedCredits);
    }

    public createCountyBillForEvent(params: IInstitutionManagerProgramEventParams & { countyAreaId: string }) {
        this.store.dispatch(EventsManagerActions.CreateCountyBillForEventAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.CreateCountyBillForEventSuccessAction, EventsManagerActions.CreateCountyBillForEventErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.CreateCountyBillForEventSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createCountyBillForEventEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        countyAreaId
    }: IInstitutionManagerProgramEventParams & { countyAreaId: string }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/events/${eventId}`, {});
    }

    public getEventSearchFileDownload(params: IInstitutionManagerProgramEventParams & {
        bulkDownloadCreate: IBulkDownloadCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.GetEventSearchFileDownloadAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.GetEventSearchFileDownloadSuccessAction, EventsManagerActions.GetEventSearchFileDownloadErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.GetEventSearchFileDownloadSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public getEventSearchFileDownloadEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        bulkDownloadCreate
    }: IInstitutionManagerProgramEventParams & {
        bulkDownloadCreate: IBulkDownloadCreateDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/download-search-files`, bulkDownloadCreate);
    }

    public updateEventHierarchyRestrictions(params: IInstitutionManagerProgramEventParams & {
        restrictionUpdate: IEventHierarchyRestrictionUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.UpdateEventHierarchyRestrictionsAction(params));

        return this.dispatcher.pipe(
            ofType(EventsManagerActions.UpdateEventHierarchyRestrictionsSuccessAction, EventsManagerActions.UpdateEventHierarchyRestrictionsErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.UpdateEventHierarchyRestrictionsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventHierarchyRestrictionsEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        restrictionUpdate
    }: IInstitutionManagerProgramEventParams & {
        restrictionUpdate: IEventHierarchyRestrictionUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/hierarchy-restrictions`, restrictionUpdate);
    }

    public downloadConsentExcel({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/consents-excel`, this.toastrService);
    }

    public downloadConsentPdf({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/consents-pdf`, this.toastrService);
    }

    public downloadConsentZip({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/consents-zip`, this.toastrService);
    }

    public downloadHealthExcel({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/health-forms-excel`, this.toastrService);
    }

    public downloadHealthPdf({ institutionId, managerId, programId, eventId }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/health-forms-pdf`, this.toastrService);
    }

    public downloadEventHealthZip({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/search/health-forms-zip`, this.toastrService);
    }

    public downloadCrossEventConsentExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/consents-excel`, this.toastrService);
    }

    public downloadCrossEventConsentPdf({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/consents-pdf`, this.toastrService);
    }

    public downloadCrossEventConsentZip({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/consents-zip`, this.toastrService);
    }

    public downloadCrossEventHealthExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-excel`, this.toastrService);
    }

    public downloadCrossEventHealthPdf({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-pdf`, this.toastrService);
    }

    public downloadCrossEventHealthZip({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-zip`, this.toastrService);
    }

    // public downloadAuditZip({ institutionId, managerId, programId, eventId, asOfDate }: IInstitutionManagerProgramEventParams & { asOfDate: string }) {
    //   downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-zip/audit?asOfDate=${asOfDate}`, this.toastrService);
    // }

    // public downloadAuditZip({ institutionId, managerId, programId, eventId, asOfDate }: IInstitutionManagerProgramEventParams & { asOfDate: string }) {
    //   downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-zip/audit?asOfDate=${asOfDate}`, this.toastrService);
    // }

    // public downloadAuditExcel({ institutionId, managerId, programId, eventId, asOfDate }: IInstitutionManagerProgramEventParams & { asOfDate: string }) {
    //   downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}/search/health-forms-excel/audit?asOfDate=${asOfDate}`, this.toastrService);
    // }

    public createCountyBillFromCredits(params: IInstitutionManagerProgramParams & {
        countyAreaId: string,
        eventId: string,
        invoiceDiscounts: string[]
    }) {
        this.store.dispatch(EventsManagerActions.CreateCountyBillFromCreditsAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.CreateCountyBillFromCreditsSuccessAction, EventsManagerActions.CreateCountyBillFromCreditsErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.CreateCountyBillFromCreditsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createCountyBillFromCreditsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        eventId,
        invoiceDiscounts
    }: IInstitutionManagerProgramParams & {
        countyAreaId: string,
        eventId: string,
        invoiceDiscounts: string[]
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/events/${eventId}/from-selection`, invoiceDiscounts);
    }

    public refundPayment(params: IInstitutionMemberProgramEventRegistrationParams & { managerId: string }) {
        this.store.dispatch(EventsManagerActions.RefundPaymentAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.RefundPaymentSuccessAction, EventsManagerActions.RefundPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.RefundPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public refundPaymentEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId,
        managerId
    }: IInstitutionMemberProgramEventRegistrationParams & { managerId: string }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/refund-payments?managerId=${managerId}`, {});
    }

    public cancelRegistration(params: IInstitutionMemberProgramEventRegistrationParams) {
        this.store.dispatch(EventsManagerActions.CancelRegistrationAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.CancelRegistrationSuccessAction, EventsManagerActions.CancelRegistrationErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.CancelRegistrationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public cancelRegistrationEffect({
        institutionId,
        memberId,
        programId,
        eventRegistrationId
    }: IInstitutionMemberProgramEventRegistrationParams) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/event-registrations/${eventRegistrationId}/cancel`, {});
    }

    public getEventCoupons(params: IInstitutionManagerProgramEventParams): Observable<IEventCouponDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEventCoupons(params)),
            tap(coupons => {
                if (coupons === undefined) {
                    this.store.dispatch(EventsManagerActions.GetEventCouponsAction(params));
                }
            }),
            filter(coupons => coupons != null)
        );
    }

    public getEventCouponsEffect({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/coupons`) as Observable<IEventCouponDto[]>;
    }

    public getEventCoupon(params: IInstitutionManagerProgramEventParams & {
        institutionDiscountId: string
    }): Observable<IEventCouponDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerEventCoupon(params)),
            tap(coupon => {
                if (coupon === undefined) {
                    this.store.dispatch(EventsManagerActions.GetEventCouponAction(params));
                }
            }),
            filter(coupon => coupon != null)
        );
    }

    public getEventCouponEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        institutionDiscountId
    }: IInstitutionManagerProgramEventParams & { institutionDiscountId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/coupons/${institutionDiscountId}`) as Observable<IEventCouponDto>;
    }

    public deleteEventCoupon(params: IInstitutionManagerProgramEventParams & { institutionDiscountId: string }) {
        this.store.dispatch(EventsManagerActions.DeleteEventCouponAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.DeleteEventCouponSuccessAction, EventsManagerActions.DeleteEventCouponErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.DeleteEventCouponSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteEventCouponEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        institutionDiscountId
    }: IInstitutionManagerProgramEventParams & { institutionDiscountId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/coupons/${institutionDiscountId}`) as Observable<IEventCouponDto>;
    }

    public createEventCoupon(params: IInstitutionManagerProgramEventParams & {
        discountCreate: IEventDiscountCreateDto
    }): Observable<IEventCouponDto> {
        this.store.dispatch(EventsManagerActions.CreateEventCouponAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.CreateEventCouponSuccessAction, EventsManagerActions.CreateEventCouponErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.CreateEventCouponSuccessAction.type) {
                    return action.coupon;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createEventCouponEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        discountCreate
    }: IInstitutionManagerProgramEventParams & {
        discountCreate: IEventDiscountCreateDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/coupons`, discountCreate, {
            observe: 'response'
        }).pipe(
            switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
        ) as Observable<IEventCouponDto>;
    }

    public updateEventCoupon(params: IInstitutionManagerProgramEventParams & {
        institutionDiscountId: string,
        update: IEventDiscountUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.UpdateEventCouponAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.UpdateEventCouponSuccessAction, EventsManagerActions.UpdateEventCouponErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.UpdateEventCouponSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventCouponEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        institutionDiscountId,
        update
    }: IInstitutionManagerProgramEventParams & {
        institutionDiscountId: string,
        update: IEventDiscountUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/coupons/${institutionDiscountId}`, update) as Observable<IEventCouponDto>;
    }

    public copyEvent(params: IInstitutionManagerProgramHierarchyParams & {
        fromEventId: string,
        eventCreateDto: IEventCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerCopyAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerCopySuccessAction, EventsManagerActions.EventsManagerCopyErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerCopySuccessAction.type) {
                    return action.event;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public copyEventEffect({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        fromEventId,
        eventCreateDto
    }: IInstitutionManagerProgramHierarchyParams & {
        fromEventId: string,
        eventCreateDto: IEventCreateDto
    }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${hierarchyNodeId}?fromEventId=${fromEventId}`, eventCreateDto,
            { observe: 'response' })
        .pipe(
            switchMap((res: HttpResponse<any>) => this.httpClient.get(res.headers.get('location')))
        ) as Observable<IEventDto>;
    }

    public downloadEventCouponsExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        institutionDiscountId
    }: IInstitutionManagerProgramParams & {
        hierarchyNodeId: string,
        institutionDiscountId: string
    }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/${hierarchyNodeId}/event-coupons/${institutionDiscountId}`, this.toastrService);
    }

    public createGroupEnrollment(params: IInstitutionManagerProgramEventParams & {
        eventGroupEnrollmentCreate: IEventGroupEnrollmentCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerCreateGroupEnrollmentAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerCreateGroupEnrollmentSuccessAction, EventsManagerActions.EventsManagerCreateGroupEnrollmentErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerCreateGroupEnrollmentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createGroupEnrollmentEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        eventGroupEnrollmentCreate
    }: IInstitutionManagerProgramEventParams & {
        eventGroupEnrollmentCreate: IEventGroupEnrollmentCreateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/group-enrollments`, eventGroupEnrollmentCreate);
    }

    public getEventGroupEnrollments(params: IInstitutionManagerProgramEventParams) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerGroupEnrollments(params)),
            tap(groupEnrollments => {
                if (groupEnrollments === undefined) {
                    this.store.dispatch(EventsManagerActions.EventsManagerGetGroupEnrollmentAction(params));
                }
            }),
            filter(groupEnrollments => groupEnrollments != null)
        );
    }

    public getEventGroupEnrollmentsEffect({
        institutionId,
        managerId,
        programId,
        eventId
    }: IInstitutionManagerProgramEventParams) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/group-enrollments`);
    }

    public updateEventEmailConfiguration(params: IInstitutionManagerProgramEventParams & {
        configuration: IEventEmailConfigurationDto
    }) {
        this.store.dispatch(EventsManagerActions.UpdateEventEmailConfigurationAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.UpdateEventEmailConfigurationSuccessAction, EventsManagerActions.UpdateEventEmailConfigurationErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.UpdateEventEmailConfigurationSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateEventEmailConfigurationEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        configuration
    }: IInstitutionManagerProgramEventParams & {
        configuration: IEventEmailConfigurationDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/email-configuration`, configuration) as Observable<IEventEmailConfigurationDto>;
    }

    public addVanityDomain(params: IInstitutionManagerProgramEventParams & {
        vanityDomainCreateDto: IEventVanityDomainCreateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerAddVanityDomainAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerAddVanityDomainSuccessAction, EventsManagerActions.EventsManagerAddVanityDomainErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerAddVanityDomainSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public addVanityDomainEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        vanityDomainCreateDto
    }: IInstitutionManagerProgramEventParams & {
        vanityDomainCreateDto: IEventVanityDomainCreateDto
    }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/vanity-domains`, vanityDomainCreateDto);
    }

    public updateVanityDomain(params: IInstitutionManagerProgramEventParams & {
        vanityDomainId: string,
        vanityDomainUpdateDto: IEventVanityDomainUpdateDto
    }) {
        this.store.dispatch(EventsManagerActions.EventsManagerUpdateVanityDomainAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerUpdateVanityDomainSuccessAction, EventsManagerActions.EventsManagerUpdateVanityDomainErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerUpdateVanityDomainSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateVanityDomainEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        vanityDomainId,
        vanityDomainUpdateDto
    }: IInstitutionManagerProgramEventParams & {
        vanityDomainId: string,
        vanityDomainUpdateDto: IEventVanityDomainUpdateDto
    }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/vanity-domains/${vanityDomainId}`, vanityDomainUpdateDto);
    }

    public deleteVanityDomain(params: IInstitutionManagerProgramEventParams & { vanityDomainId: string }) {
        this.store.dispatch(EventsManagerActions.EventsManagerDeleteVanityDomainAction(params));
        return this.dispatcher.pipe(
            ofType(EventsManagerActions.EventsManagerDeleteVanityDomainSuccessAction, EventsManagerActions.EventsManagerDeleteVanityDomainErrorAction),
            take(1),
            map(action => {
                if (action.type === EventsManagerActions.EventsManagerDeleteVanityDomainSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteVanityDomainEffect({
        institutionId,
        managerId,
        programId,
        eventId,
        vanityDomainId
    }: IInstitutionManagerProgramEventParams & { vanityDomainId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/events/${eventId}/vanity-domains/${vanityDomainId}`);
    }
}
