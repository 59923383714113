import { eEventRegistrationStatus } from '../enums';
import { IEventRegistrationTypeDescriptorDto } from './IEventRegistrationTypeDescriptorDto';
import { IEventSessionDto } from './IEventSessionDto';

export interface IMemberRegistrationDescriptorDto {
    registrationId: string;
    registrationType: IEventRegistrationTypeDescriptorDto;
    sessions: IEventSessionDto[];
    status: eEventRegistrationStatus;
    additionalPaymentRequired?: boolean;
}
