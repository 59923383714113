import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { IEventDto } from '../../../../../models';
import { EventsManagerService, RouterService } from '../../../../../services';

@Component({
  selector: 'ng4h-modal-edit-event-instructions',
  templateUrl: './modal-edit-event-instructions.component.html',
  styleUrls: ['./modal-edit-event-instructions.component.scss']
})
export class ModalEditEventInstructionsComponent {

  public eventForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ModalEditEventInstructionsComponent>,
    private fb: FormBuilder,
    private eventsManagerService: EventsManagerService,
    private routerService: RouterService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public event: IEventDto
  ) {

    this.eventForm = this.fb.group({
      eventInstructions: [event.eventInstructions]
    });
  }

  public save() {
    this.eventsManagerService.updateEventInstructions({
      institutionId: this.routerService.institutionId,
      managerId: this.routerService.managerId,
      programId: this.routerService.programId,
      eventId: this.routerService.eventId,
      eventInstructionsUpdateDto: {
        eventInstructions: this.eventForm.value.eventInstructions
      }
    }).subscribe({
      next: () => {
        this.toastrService.success('update event success');
        this.close();
      }, error: (error) => {
        this.toastrService.error('event update failed');
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
