import { Routes } from '@angular/router';

import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { RootRoutingComponent } from './core/components/root-routing/root-routing.component';
import { AdminGuard } from './router/guards/admin.guard';
import { AuthenticationGuard } from './router/guards/authentication.guard';
import { FamilyGuard } from './router/guards/family.guard';
import { MemberGuard } from './router/guards/member.guard';
import { ProfileGuard } from './router/guards/profile.guard';
import { SuperuserGuard } from './router/guards/superuser.guard';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RootRoutingComponent
  },
  {
    path: ':userAccountId/:superuserId/superuser',
    loadChildren: () => import('./core/containers/superuser/superuser.module').then(m => m.SuperuserModule),
    canActivate: [AuthenticationGuard, SuperuserGuard]
  }, {
    path: ':userAccountId/:institutionId/:loggedInInstitutionProfileId/institution',
    loadChildren: () => import('./core/containers/institution/institution.module').then(m => m.InstitutionModule),
    canActivate: [AuthenticationGuard, ProfileGuard, AdminGuard]
  }, {
    path: ':userAccountId/:institutionId/:loggedInInstitutionProfileId/admin',
    loadChildren: () => import('./core/containers/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthenticationGuard, ProfileGuard, AdminGuard]
  },
  {
    path: ':userAccountId/:institutionId/:loggedInInstitutionProfileId/family',
    loadChildren: () => import('./core/containers/family/family.module').then(m => m.FamilyModule),
    canActivate: [AuthenticationGuard, ProfileGuard, FamilyGuard],
  },
  {
    path: ':userAccountId/:institutionId/:loggedInInstitutionProfileId/member',
    loadChildren: () => import('./core/containers/member/member.module').then(m => m.MemberModule),
    canActivate: [AuthenticationGuard, ProfileGuard, MemberGuard],
  },
  {
    path: ':userAccountId/:institutionId/:loggedInInstitutionProfileId/report-consumer',
    loadChildren: () => import('./core/containers/report-consumer/report-consumer.module').then(m => m.ReportConsumerModule),
    canActivate: [AuthenticationGuard, ProfileGuard, AdminGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./core/containers/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'public/:institutionId/:eventId',
    loadChildren: () => import('./core/containers/public/public.module').then(m => m.PublicModule)
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];
