import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { downloadFile, downloadFileWithBody } from 'app/shared/utils';
import { filter, map, Observable, of, take, tap } from 'rxjs';

import { ManagerEnrollmentActions } from '../containers/admin/manager/enrollments/manager-enrollments/store';
import {
    getCollectedCreditEnrollments,
    getCountyAreaEnrollmentSettings,
    getCountyCreditBill,
    getCountyCreditBills,
    getCountyCreditBillsByProgramYear,
    getEnrollmentBillPreview,
    getEnrollmentFeeWaiverRequests,
    getManagerSubmittedEnrollments,
    getManagerUnitRequests,
    getMangerGroupEnrollments,
    getMemberCredits,
    getOutstandingCreditEnrollments,
    getRegistrationBillPreview,
    ManagerFeatureStore
} from '../containers/admin/manager/manager-feature.reducer';
import { MemberActions } from '../containers/member';
import {
    ICountyCreditBillMemberDto,
    ICountyCreditBillPaidDto,
    ICountyCreditBillPreviewDto,
    ICreditDto,
    ICreditsCollectionDto,
    ICreditSearchDto,
    ICreditSearchResultsDto,
    IEnrollmentApprovalDto,
    IEnrollmentCommentDto,
    IEnrollmentFeeWaiverRequestDeclinedDto,
    IEnrollmentFeeWaiverRequestSearchResultsDto,
    IEnrollmentUnitAdditionRequestDto,
    IEnrollmentUnitAdditionRequestUpdateDto,
    IGroupEnrollmentCreateDto,
    IGroupEnrollmentDto,
    IInstitutionManagerProgramHierarchyParams,
    IInstitutionManagerProgramParams,
    IInstitutionMemberProgramEnrollmentParams,
    IManagerCommentDto,
    IMemberEnrollmentEntityDto,
    IProgramCountyAreaSettingsDto,
    IProgramCountyAreaSettingsUpdateDto
} from '../models';
import { ICountyCreditBillDto } from '../models/serverDTOs/ICountyCreditBillDto';
import { IGroupEnrollmentUpdateDto } from '../models/serverDTOs/IGroupEnrollmentUpdateDto';
import { CommonToastrService } from './common-toastr.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManagerEnrollmentService {

    constructor(private store: Store<ManagerFeatureStore>, private httpClient: HttpClient, private dispatcher: ActionsSubject, private toastrService: CommonToastrService) {

    }

    public refreshSubmittedEnrollments() {
        this.store.dispatch(ManagerEnrollmentActions.InvalidateManagerEnrollmentsCache());
    }

    public getSubmittedEnrollments(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<IMemberEnrollmentEntityDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getManagerSubmittedEnrollments(params)),
            tap(enrollment => {
                if (enrollment === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetManagerGetEnrollments(params));
                }
            }),
            filter(enrollment => enrollment != null)
        );
    }

    public getSubmittedEnrollmentsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/enrollments/submitted?countyAreaId=${countyAreaId}`) as Observable<IMemberEnrollmentEntityDto[]>;
    }

    public getOutstandingCreditEnrollments(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<ICreditDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getOutstandingCreditEnrollments(params)),
            tap(enrollment => {
                if (enrollment === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.ManagerGetOutstandingCreditsAction(params));
                }
            }),
            filter(enrollment => enrollment != null)
        );
    }

    public getOutstandingCreditEnrollmentsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        programYearId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/outstanding-credits/${countyAreaId}?programYearId=${programYearId}`) as Observable<ICreditDto[]>;
    }

    public outstandingCreditsSearch(params: IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string, searchDto: ICreditSearchDto }) {
        this.store.dispatch(ManagerEnrollmentActions.ManagerOutstandingCreditSearchAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.ManagerOutstandingCreditSearchSuccessAction, ManagerEnrollmentActions.ManagerOutstandingCreditSearchErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.ManagerOutstandingCreditSearchSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public outstandingCreditsSearchEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        pagesize,
        pageindex,
        searchDto
    }: IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string, searchDto: ICreditSearchDto }): Observable<ICreditSearchResultsDto> {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/outstanding-credits?programYearId=${programYearId}&pagesize=${pagesize}&pageindex=${pageindex}`, searchDto) as Observable<ICreditSearchResultsDto>;
    }

    public getCollectedCreditEnrollments(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<ICreditDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getCollectedCreditEnrollments(params)),
            tap(enrollment => {
                if (enrollment === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.ManagerGetCollectedCreditsAction(params));
                }
            }),
            filter(enrollment => enrollment != null)
        );
    }

    public getCollectedCreditEnrollmentsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        programYearId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/collected-credits/${countyAreaId}?programYearId=${programYearId}`) as Observable<ICreditDto[]>;
    }

    // Mark a credit as received and paid from member
    // County managers only
    public confirmCreditCollected(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, creditsCollected: ICreditsCollectionDto }) {
        this.store.dispatch(ManagerEnrollmentActions.ManagerConfirmCollectedCreditsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.ManagerConfirmCollectedCreditsSuccessAction, ManagerEnrollmentActions.ManagerConfirmCollectedCreditsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.ManagerConfirmCollectedCreditsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public confirmCreditCollectedEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        programYearId,
        creditsCollected
    }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, creditsCollected: ICreditsCollectionDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/credit-collection/${countyAreaId}?programYearId=${programYearId}`, creditsCollected);
    }

    public confirmInstitutionCreditCollected(params: IInstitutionManagerProgramParams & { programYearId: string, creditsCollected: ICreditsCollectionDto }) {
        this.store.dispatch(ManagerEnrollmentActions.ManagerConfirmInstitutionCollectedCreditsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.ManagerConfirmInstitutionCollectedCreditsSuccessAction, ManagerEnrollmentActions.ManagerConfirmInstitutionCollectedCreditsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.ManagerConfirmInstitutionCollectedCreditsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public confirmInstitutionCreditCollectedEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        creditsCollected
    }: IInstitutionManagerProgramParams & { programYearId: string, creditsCollected: ICreditsCollectionDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/credit-collection?programYearId=${programYearId}`, creditsCollected);
    }

    public approve(params: IInstitutionMemberProgramEnrollmentParams & { managerId: string, approval: IEnrollmentApprovalDto }) {
        this.store.dispatch(ManagerEnrollmentActions.EnrollmentApproveAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.EnrollmentApproveSuccessAction, ManagerEnrollmentActions.EnrollmentApproveErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.EnrollmentApproveSuccessAction.type) {
                    return action;
                } else {
                    throw (action.error);
                }
            })
        );
    }

    public approveEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId,
        approval
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string, approval: IEnrollmentApprovalDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/approve?managerId=${managerId}`, approval);
    }

    public sendBack(params: IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }) {
        this.store.dispatch(ManagerEnrollmentActions.EnrollmentSendBackAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.EnrollmentSendBackSuccessAction, ManagerEnrollmentActions.EnrollmentSendBackErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.EnrollmentSendBackSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public sendBackEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId,
        comment
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/send-back?managerId=${managerId}`, comment);
    }

    public block(params: IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }) {
        this.store.dispatch(ManagerEnrollmentActions.EnrollmentBlockAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.EnrollmentBlockSuccessAction, ManagerEnrollmentActions.EnrollmentBlockErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.EnrollmentBlockSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public blockEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId,
        comment
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string, comment: IEnrollmentCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/reject?managerId=${managerId}`, comment);
    }

    public unBlock(params: IInstitutionMemberProgramEnrollmentParams & { comment: IManagerCommentDto }) {
        this.store.dispatch(MemberActions.EnrollmentUnBlockAction(params));
        return this.dispatcher.pipe(
            ofType(MemberActions.EnrollmentUnBlockSuccessAction, MemberActions.EnrollmentUnBlockErrorAction),
            take(1),
            map(action => {
                if (action.type === MemberActions.EnrollmentUnBlockSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public unBlockEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        comment
    }: IInstitutionMemberProgramEnrollmentParams & { comment: IManagerCommentDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/un-reject`, comment);
    }

    ///////////////////////
    /// GROUP ENROLLMENT
    ///////////////////////

    public downloadGroupEnrollmentExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId,
        programYearId
    }: IInstitutionManagerProgramParams & { hierarchyNodeId: string, programYearId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/group-enrollments/${hierarchyNodeId}/program-years/${programYearId}`, this.toastrService);
    }

    public getGroupEnrollment(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string, groupEnrollmentId: string }) {
        return this.getGroupEnrollments(params).pipe(map(enrollments => enrollments.find(groupEnrollment => groupEnrollment.groupEnrollmentId === params.groupEnrollmentId)));
    }

    public getGroupEnrollments(params: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }): Observable<IGroupEnrollmentDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getMangerGroupEnrollments(params)),
            tap(groupEnrollment => {
                if (groupEnrollment === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetGroupEnrollmentsAction(params));
                }
            }),
            filter(groupEnrollment => groupEnrollment != null)
        );
    }

    public getGroupEnrollmentsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        programYearId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, programYearId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/group-enrollments/${countyAreaId}?programYearId=${programYearId}`) as Observable<IGroupEnrollmentDto[]>;

    }

    public createGroupEnrollment(params: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollment: IGroupEnrollmentCreateDto }) {
        this.store.dispatch(ManagerEnrollmentActions.CreateGroupEnrollmentsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.CreateGroupEnrollmentsSuccessAction, ManagerEnrollmentActions.CreateGroupEnrollmentsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.CreateGroupEnrollmentsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createGroupEnrollmentEffect({
        institutionId,
        managerId,
        programId,
        groupEnrollment,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollment: IGroupEnrollmentCreateDto }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/group-enrollments/${countyAreaId}`, groupEnrollment);
    }

    public updateGroupEnrollment(params: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string, groupEnrollmentUpdate: IGroupEnrollmentUpdateDto }) {
        this.store.dispatch(ManagerEnrollmentActions.UpdateGroupEnrollmentsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.UpdateGroupEnrollmentsSuccessAction, ManagerEnrollmentActions.UpdateGroupEnrollmentsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.UpdateGroupEnrollmentsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateGroupEnrollmentEffect({
        institutionId,
        managerId,
        programId,
        groupEnrollmentId,
        groupEnrollmentUpdate,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string, groupEnrollmentUpdate: IGroupEnrollmentUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/group-enrollments/${countyAreaId}/${groupEnrollmentId}`, groupEnrollmentUpdate);
    }

    public deleteGroupEnrollment(params: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string }) {
        this.store.dispatch(ManagerEnrollmentActions.DeleteGroupEnrollmentsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.DeleteGroupEnrollmentsSuccessAction, ManagerEnrollmentActions.DeleteGroupEnrollmentsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.DeleteGroupEnrollmentsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public deleteGroupEnrollmentEffect({
        institutionId,
        managerId,
        programId,
        groupEnrollmentId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string, groupEnrollmentId: string }) {
        return this.httpClient.delete(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/group-enrollments/${countyAreaId}/${groupEnrollmentId}`);
    }

    public processPaymentsEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/process-payments?managerId=${managerId}`, {});
    }

    // public getAllCountyCreditBills(params: IInstitutionManagerProgramParams): Observable<ICountyCreditBillDto[]> {
    //   if (Object.keys(params).find(key => params[key] == null) != null) {
    //     return of(null);
    //   }
    //   return this.store.pipe(
    //     select(getAllCountyCreditBills(params)),
    //     tap(bills => {
    //       if (bills === undefined) {
    //         this.store.dispatch(ManagerEnrollmentActions.GetAllCountyCreditBillsAction(params));
    //       }
    //     }),
    //     filter(bills => bills != null),
    //   );
    // }

    // public getAllCountyCreditBillsEffect({ institutionId, managerId, programId }: IInstitutionManagerProgramParams) {
    //   return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills`) as Observable<ICountyCreditBillDto[]>;
    // }

    public getCountyCreditBillsForProgramYear(params: IInstitutionManagerProgramParams & { programYearId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getCountyCreditBillsByProgramYear(params)),
            tap(bills => {
                if (bills === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetCountyCreditBillsForProgramYearAction(params));
                }
            }),
            filter(bills => bills != null)
        );
    }

    // Get all county credit bills by county and program year
    public getCountyCreditBillsForProgramYearEffect({
        institutionId,
        managerId,
        programId,
        programYearId
    }: IInstitutionManagerProgramParams & { programYearId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills?programYearId=${programYearId}`) as Observable<ICountyCreditBillDto[]>;
    }

    public getCountyCreditBills(params: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getCountyCreditBills(params)),
            tap(bills => {
                if (bills === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetCountyCreditBillsAction(params));
                }
            }),
            filter(bills => bills != null)
        );
    }

    // Get all county credit bills by county
    public getCountyCreditBillsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}`) as Observable<ICountyCreditBillDto[]>;
    }

    public getCountyCreditBill(params: IInstitutionManagerProgramParams & { countyCreditBillId: string }): Observable<ICountyCreditBillDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getCountyCreditBill(params)),
            tap(bills => {
                if (bills === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetCountyCreditBillAction(params));
                }
            }),
            filter(bills => bills != null)
        );
    }

    // Get county credit bill by id
    public getCountyCreditBillEffect({
        institutionId,
        managerId,
        programId,
        countyCreditBillId
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyCreditBillId}`) as Observable<ICountyCreditBillDto>;
    }

    public getEnrollmentBillPreview(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<ICountyCreditBillPreviewDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getEnrollmentBillPreview(params)),
            tap(bills => {
                if (bills === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetEnrollmentBillPreviewAction(params));
                }
            }),
            filter(bills => bills != null)
        );
    }

    // Get a new county bill preview from currently "Approved" enrollments or registrations
    public getEnrollmentBillPreviewEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/enrollments`) as Observable<ICountyCreditBillPreviewDto>;
    }

    public getRegistrationBillPreview(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<any> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getRegistrationBillPreview(params)),
            tap(bills => {
                if (bills === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetRegistrationBillPreviewAction(params));
                }
            }),
            filter(bills => bills != null)
        );
    }

    public getRegistrationBillPreviewEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/events`) as Observable<any>;
    }

    public createCountyBillFromApproved(params: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        this.store.dispatch(ManagerEnrollmentActions.CreateCountyBillFromApprovedAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.CreateCountyBillFromApprovedSuccessAction, ManagerEnrollmentActions.CreateCountyBillFromApprovedErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.CreateCountyBillFromApprovedSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    // Create a new county bill from currently "Approved" enrollments
    public createCountyBillFromApprovedEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/enrollments`, {});
    }

    public confirmCountyCreditPaid(params: IInstitutionManagerProgramParams & { countyCreditBillId: string, countyCreditPaid: ICountyCreditBillPaidDto }) {
        this.store.dispatch(ManagerEnrollmentActions.ConfirmCountyCreditAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.ConfirmCountyCreditSuccessAction, ManagerEnrollmentActions.ConfirmCountyCreditErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.ConfirmCountyCreditSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    // Mark a county bill as received and paid
    // State managers only
    public confirmCountyCreditPaidEffect({
        institutionId,
        managerId,
        programId,
        countyCreditBillId,
        countyCreditPaid
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string, countyCreditPaid: ICountyCreditBillPaidDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credits/${countyCreditBillId}`, countyCreditPaid);
    }

    public getMemberCreditsOnBill(params: IInstitutionManagerProgramParams & { countyCreditBillId: string }): Observable<ICountyCreditBillMemberDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getMemberCredits(params)),
            tap(bill => {
                if (bill === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetMemberCreditsOnBillAction(params));
                }
            }),
            filter(bill => bill != null)
        );
    }

    // Get all the members who's invoice is on the county bill
    public getMemberCreditsOnBillEffect({
        institutionId,
        managerId,
        programId,
        countyCreditBillId
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyCreditBillId}/members`) as Observable<ICountyCreditBillMemberDto[]>;
    }

    public searchEnrollmentFeeWaiverRequests(params: IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string }): Observable<IEnrollmentFeeWaiverRequestSearchResultsDto> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        this.store.dispatch(ManagerEnrollmentActions.LoadEnrollmentFeeWaiverRequests(params));

    }

    public getEnrollmentFeeWaiverRequests(params: IInstitutionManagerProgramParams & { programYearId: string }): Observable<IEnrollmentFeeWaiverRequestSearchResultsDto> {

        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getEnrollmentFeeWaiverRequests(params))
        );

    }

    public getEnrollmentFeeWaiverRequestsEffect({
        institutionId,
        managerId,
        programId,
        programYearId,
        pagesize,
        pageindex
    }: IInstitutionManagerProgramParams & { programYearId: string, pagesize: string, pageindex: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/enrollment-fee-waive-requests?programYearId=${programYearId}&pagesize=${pagesize}&pageindex=${pageindex}`) as Observable<IEnrollmentFeeWaiverRequestSearchResultsDto>;
    }

    public waiveEnrollmentFees(params: IInstitutionMemberProgramEnrollmentParams) {
        this.store.dispatch(ManagerEnrollmentActions.WaiveEnrollmentFeesAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.WaiveEnrollmentFeesSuccessAction, ManagerEnrollmentActions.WaiveEnrollmentFeesErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.WaiveEnrollmentFeesSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public waiveEnrollmentFeesEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId
    }: IInstitutionMemberProgramEnrollmentParams) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/accept-waive-request`, {});
    }

    public declineWaiverRequest(params: IInstitutionMemberProgramEnrollmentParams & { declineDto: IEnrollmentFeeWaiverRequestDeclinedDto }) {
        this.store.dispatch(ManagerEnrollmentActions.DeclineWaiverRequestAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.DeclineWaiverRequestSuccessAction, ManagerEnrollmentActions.DeclineWaiverRequestErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.DeclineWaiverRequestSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public declineWaiverRequestEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        declineDto
    }: IInstitutionMemberProgramEnrollmentParams & { declineDto: IEnrollmentFeeWaiverRequestDeclinedDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/decline-waive-request`, declineDto);
    }

    public getManagerEnrollmentSettings(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<IProgramCountyAreaSettingsDto> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }
        return this.store.pipe(
            select(getCountyAreaEnrollmentSettings(params)),
            tap(settings => {
                if (settings === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.LoadCountyAreaEnrollmentSettings(params));
                }
            }),
            filter(settings => settings != null)
        );

    }

    public getManagerEnrollmentSettingsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/settings`) as Observable<IProgramCountyAreaSettingsDto>;
    }

    public updateManagerEnrollmentSettings(params: IInstitutionManagerProgramParams & { countyAreaId: string, update: IProgramCountyAreaSettingsUpdateDto }) {
        this.store.dispatch(ManagerEnrollmentActions.UpdateCountyAreaEnrollmentSettings(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.UpdateCountyAreaEnrollmentSuccessSettings, ManagerEnrollmentActions.UpdateCountyAreaEnrollmentErrorSettings),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.UpdateCountyAreaEnrollmentSuccessSettings.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public updateManagerEnrollmentSettingsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        update
    }: IInstitutionManagerProgramParams & { countyAreaId: string, update: IProgramCountyAreaSettingsUpdateDto }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-areas/${countyAreaId}/settings`, update);
    }

    public getCountyBillInvoicePdfEffect({
        institutionId,
        managerId,
        programId,
        countyCreditBillId
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyCreditBillId}/invoice-pdf`);
    }

    public refundPayment(params: IInstitutionMemberProgramEnrollmentParams & { paymentId: string, managerId: string }) {
        this.store.dispatch(ManagerEnrollmentActions.RefundEnrollmentPaymentAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.RefundEnrollmentPaymentSuccessAction, ManagerEnrollmentActions.RefundEnrollmentPaymentErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.RefundEnrollmentPaymentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public refundPaymentEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        paymentId,
        managerId
    }: IInstitutionMemberProgramEnrollmentParams & { paymentId: string, managerId: string }) {
        return this.httpClient.put(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/refund-payments/${paymentId}?managerId=${managerId}`, {});
    }

    public createCountyBillFromCredits(params: IInstitutionManagerProgramParams & { countyAreaId: string, invoiceDiscounts: string[] }) {
        this.store.dispatch(ManagerEnrollmentActions.CreateCountyBillFromCreditsAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.CreateCountyBillFromCreditsSuccessAction, ManagerEnrollmentActions.CreateCountyBillFromCreditsErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.CreateCountyBillFromCreditsSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public createCountyBillFromCreditsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId,
        invoiceDiscounts
    }: IInstitutionManagerProgramParams & { countyAreaId: string, invoiceDiscounts: string[] }) {
        return this.httpClient.post(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/county-credit-bills/${countyAreaId}/enrollments/from-selection`, invoiceDiscounts);
    }

    public cancelEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { managerId: string }) {
        this.store.dispatch(ManagerEnrollmentActions.CancelEnrollmentAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.CancelEnrollmentSuccessAction, ManagerEnrollmentActions.CancelEnrollmentErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.CancelEnrollmentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public cancelEnrollmentEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/cancel?managerId=${managerId}`, {});
    }

    public notParticipatingEnrollment(params: IInstitutionMemberProgramEnrollmentParams & { managerId: string }) {
        this.store.dispatch(ManagerEnrollmentActions.NotParticipatingEnrollmentAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.NotParticipatingEnrollmentSuccessAction, ManagerEnrollmentActions.NotParticipatingEnrollmentErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.NotParticipatingEnrollmentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public notParticipatingEnrollmentEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        managerId
    }: IInstitutionMemberProgramEnrollmentParams & { managerId: string }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/not-participating?managerId=${managerId}`, {});
    }

    public participatingEnrollment(params: IInstitutionMemberProgramEnrollmentParams) {
        this.store.dispatch(MemberActions.ParticipatingEnrollmentAction(params));
        return this.dispatcher.pipe(
            ofType(MemberActions.ParticipatingEnrollmentSuccessAction, MemberActions.ParticipatingEnrollmentErrorAction),
            take(1),
            map(action => {
                if (action.type === MemberActions.ParticipatingEnrollmentSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public participatingEnrollmentEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId
    }: IInstitutionMemberProgramEnrollmentParams) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/participating`, {});
    }

    public downloadApprovedExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-enrollments/approved/excel`, {});
    }

    public downloadSubmittedExcel({
        institutionId,
        managerId,
        programId,
        hierarchyNodeId
    }: IInstitutionManagerProgramHierarchyParams) {
        downloadFileWithBody(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/${hierarchyNodeId}/search-enrollments/submitted/excel`, {});
    }

    public approveUnitAddRequest(params: IInstitutionMemberProgramEnrollmentParams & { enrollmentUnitProgramYearRequestId: string, requestUpdate: IEnrollmentUnitAdditionRequestUpdateDto }) {
        this.store.dispatch(ManagerEnrollmentActions.ApproveUnitAddRequestAction(params));
        return this.dispatcher.pipe(
            ofType(ManagerEnrollmentActions.ApproveUnitAddRequestSuccessAction, ManagerEnrollmentActions.ApproveUnitAddRequestErrorAction),
            take(1),
            map(action => {
                if (action.type === ManagerEnrollmentActions.ApproveUnitAddRequestSuccessAction.type) {
                    return action;
                } else {
                    throw action.error;
                }
            })
        );
    }

    public approveUnitAddRequestEffect({
        institutionId,
        memberId,
        programId,
        enrollmentId,
        enrollmentUnitProgramYearRequestId,
        requestUpdate
    }: IInstitutionMemberProgramEnrollmentParams & { enrollmentUnitProgramYearRequestId: string, requestUpdate: IEnrollmentUnitAdditionRequestUpdateDto }) {
        return this.httpClient.patch(`${environment.apiUri}/api/institutions/${institutionId}/members/${memberId}/programs/${programId}/enrollments/${enrollmentId}/unit-add-requests/${enrollmentUnitProgramYearRequestId}`, requestUpdate);
    }

    public getUnitRequests(params: IInstitutionManagerProgramParams & { countyAreaId: string }): Observable<IEnrollmentUnitAdditionRequestDto[]> {
        if (Object.keys(params).find(key => params[key] == null) != null) {
            return of(null);
        }

        return this.store.pipe(
            select(getManagerUnitRequests(params)),
            tap(clubs => {
                if (clubs === undefined) {
                    this.store.dispatch(ManagerEnrollmentActions.GetUnitRequestsAction(params));
                }
            }),
            filter(clubs => clubs != null)
        );
    }

    public getUnitRequestsEffect({
        institutionId,
        managerId,
        programId,
        countyAreaId
    }: IInstitutionManagerProgramParams & { countyAreaId: string }) {
        return this.httpClient.get(`${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/members/unit-addition-requests?countyAreaId=${countyAreaId}`) as Observable<IEnrollmentUnitAdditionRequestDto[]>;
    }

    public downloadCountyChecksFromCountyBills({
        institutionId,
        managerId,
        programId,
        countyCreditBillId
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/county-bill-checks/${countyCreditBillId}`, this.toastrService);
    }

    public downloadCountyChecksReceived({
        institutionId,
        managerId,
        programId,
        countyCreditBillId
    }: IInstitutionManagerProgramParams & { countyCreditBillId: string }) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/county-bill-checks/${countyCreditBillId}`);
    }

    public downloadCollectedChecksNotOnCountyBillExcel({
        institutionId,
        managerId,
        programId
    }: IInstitutionManagerProgramParams) {
        downloadFile(this.httpClient, `${environment.apiUri}/api/institutions/${institutionId}/managers/${managerId}/programs/${programId}/quick-exports/collected-checks-not-on-county-bill`, this.toastrService);
    }
}
