import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducers';
import { ErrorActions } from 'app/shared/error';
import { combineLatest, filter, map, Observable, of, take } from 'rxjs';

import { ProfileService, RouterService } from '.';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';

export enum eLogLevel {
    Trace = 'Trace',
    Debug = 'Debug',
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error',
    Fatal = 'Fatal',
    SystemEvent = 'SystemEvent',
    Off = 'Off',
}

interface SystemError {
    level: eLogLevel;
    message: string;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    private timeSinceLastError: number;

    constructor(private store: Store<AppState>, private httpClient: HttpClient, private routerService: RouterService, private userService: UserService, private profileService: ProfileService) {
    }

    public httpError(error: HttpErrorResponse, req: HttpRequest<any>) {
        this.store.dispatch(ErrorActions.ErrorHttpAction({ error, req }));
    }

    public report(error: Error): void {

        console.error('error report', error);

        if (error == null || error.message === 'Credentials Expired' || Object.keys(error).length === 0 || error instanceof HttpErrorResponse) {
            return;
        }

        if (this.timeSinceLastError != null && this.timeSinceLastError + 10000 > new Date().getTime()) {
            return;
        }
        this.timeSinceLastError = new Date().getTime();

        combineLatest([this.userService.userAccount$, this.profileService.loggedInAsInstitutionProfile, this.profileService.actingAsInstitutionProfile]).pipe(
            filter(combined => combined.every(c => c != null)),
            take(1)
        ).subscribe(([userAccount, activeUserInstitutionProfile, actingOnBehalfOf]) => {

            let stringifyError = '';
            try {
                stringifyError = JSON.stringify(error);
            } catch (e) {

            }

            const systemError: SystemError = {
                level: eLogLevel.Error,
                message: error.message,
                data: {
                    error: stringifyError,
                    currentRoute: this.routerService.currentRoute,
                    lastRoute: this.routerService.lastRoute,
                    userAccount,
                    activeUserInstitutionProfile,
                    actingOnBehalfOf
                }
            };
            this.httpClient.post(`${environment.apiUri}/api/system/errors`, systemError).pipe(take(1)).subscribe();
        });
    }

    public getBackEndErrors(superuserId: string, logLevel: string) {
        if (superuserId == null) {
            return of(null);
        }
        return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/back-end-errors/${logLevel}`) as Observable<any[]>;
    }

    public getFrontEndErrors(superuserId: string, logLevel: string) {
        if (superuserId == null) {
            return of(null);
        }
        return this.httpClient.get(`${environment.apiUri}/api/super-users/${superuserId}/front-end-errors/${logLevel}`).pipe(
            filter((errors: SystemError[]) => Array.isArray(errors)),
            map((errors: SystemError[]) => errors.map(error => {
                return error.data ? { ...error, data: JSON.parse(error.data) } : error;
            }))) as Observable<SystemError[]>;
    }
}
