export interface ILoginCredentials {
    accessToken: string;
    accountLockedUntil: string;
    expiresAt: number;
    failedLoginCount: number;
    loginAttemptsUntilLock: number;
    message: string;
    status: LoginStatus;
    userAccountAuth: string;
    userAccountToken: string;
    '2FARequired'?: string;
    '2FAVerified': boolean;
    passwordChangeRequired: boolean;
    passwordExpired: boolean;
    userAccountId: string;
}

export enum LoginStatus {
    Error = 'Error',
    Success = 'Success',
    InvalidUserNameOrPassword = 'InvalidUserNameOrPassword',
    ProfileNotFound = 'ProfileNotFound',
    AccountLocked = 'AccountLocked',
    AccountInactive = 'AccountInactive',
    PasswordExpired = 'PasswordExpired',
    PasswordChangeRequired = 'PasswordChangeRequired',
}
